import React, { useMemo, useState } from 'react';

import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useTheme,
  useMediaQuery,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

import { UserIcon } from '@/assets/icons/UserIcon';
import Logo from '@/assets/images/LaverCup/logo.png';
import Burger from '@/assets/images/OlyBet/icon/burger.svg';

import { useSidebarContext } from '@/context/sidebar.context';
import { useUserContext } from '@/context/user.context';

import LangSwitcher from '@/elements/LangSwitcher';

import { MediaQueriesEnum } from '@/enum/mediaQueries.enum';
import { RoutesEnum } from '@/enum/routes.enum';
import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';

import { triggerGTAGEvent } from '@/utils/analyticTracks';
import { enabledLanguages } from '@/utils/i18n';

const Header: React.FC = () => {
  const [isDesktop] = useMediaQuery(MediaQueriesEnum.isDesktop, { ssr: false });
  const { onSidebarOpen } = useSidebarContext();
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();
  const { user } = useUserContext();
  const { value: jwt, removeItem: removeJWT } = useLocalStorage<string>(
    StorageKeysEnum.jwt,
  );
  const { removeItem: removeUserID } = useLocalStorage<string>(
    StorageKeysEnum.user_id,
  );

  const [logoLoaded, setLogoLoaded] = useState(false);

  const isLangSwitcherEnabled = useMemo(() => enabledLanguages.length > 1, []);

  const goToSignup = () => {
    triggerGTAGEvent('signup.step1');
    navigate(RoutesEnum.REGISTRATION);
  };

  const goToLogin = () => {
    navigate(RoutesEnum.LOGIN);
  };

  const goToHome = () => {
    navigate(RoutesEnum.HOME);
  };

  const handleLogout = () => {
    removeJWT();
    removeUserID();
  };

  const isHeaderShown = useMemo(
    () => pathname !== RoutesEnum.SSOENTRY,
    [pathname],
  );

  return (
    <Box
      as='header'
      display={isHeaderShown ? 'block' : 'none'}
      left='0'
      position='fixed'
      top='0'
      w='100%'
      zIndex='997'
    >
      <Box
        background={theme.colors.header.primaryBg}
        h={{ base: '70px', lg: '96px' }}
        px='20px'
        py='0'
        w='100%'
      >
        <Flex
          align='center'
          h='100%'
          justify='space-between'
          position='relative'
          w='100%'
        >
          <Box mx='auto' w={isDesktop ? '224px' : '160px'} zIndex='1'>
            <Button onClick={goToHome} p='0' variant='ghost'>
              <Image
                alt=''
                display={logoLoaded ? 'block' : 'none'}
                onLoad={() => setLogoLoaded(true)}
                src={Logo}
              />
            </Button>
          </Box>

          <Flex
            align='center'
            gap='10px'
            justify='flex-end'
            position='absolute'
            top='50%'
            transform='translateY(-50%)'
            w='100%'
          >
            {isLangSwitcherEnabled && <LangSwitcher />}
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default Header;
